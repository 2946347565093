import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilHouse,
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilDescription,
  cilDrop,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSatelite,
  cilSpeedometer,
  cilContact,
  cilStar,
  cibCodeigniter,
  cilCast,
  cilEnvelopeOpen,
  cibGreensock,
  cilBriefcase,
} from '@coreui/icons'
// import * as icons from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

// console.log(icons)

const _nav = [
  {
    component: CNavItem,
    name: 'Home',
    to: '/',
    icon: <CIcon icon={cilHouse} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      text: '',
      needsLoggedIn: false,
    },
  },
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      text: '',
      needsLoggedIn: true,
    },
  },
  {
    component: CNavItem,
    name: 'Profile',
    to: '/profile',
    icon: <CIcon icon={cilContact} customClassName="nav-icon" />,
    badge: {
      color: 'info',
      text: '',
      needsLoggedIn: true,
    },
  },
  // {
  //   component: CNavTitle,
  //   name: '',
  // },
  // {
  //   component: CNavItem,
  //   // name: `Portfolio Page`,
  //   name: `Info`,
  //   // to: 'https://pasciaks.github.io/',
  //   to: '/terms',
  //   // target: `_blank`,
  //   icon: <CIcon icon={cilBriefcase} customClassName="nav-icon" />,
  //   badge: {
  //     color: 'info',
  //     text: '?',
  //   },
  // },
  // {
  //   component: CNavItem,
  //   name: 'Lostwords',
  //   to: 'http://lostwords.org',
  //   target: `_blank`,
  //   icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
  //   badge: {
  //     color: 'info',
  //     text: '',
  //   },
  // },
  // {
  //   component: CNavItem,
  //   name: 'NPM Package',
  //   to: 'https://www.npmjs.com/package/@pasciaks/lostwords-org-library',
  //   target: `_blank`,
  //   icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
  //   badge: {
  //     color: 'info',
  //     text: '',
  //   },
  // },

  // {
  //   component: CNavItem,
  //   name: 'NPM Package Demo',
  //   to: 'https://sheldon.pasciak.com/',
  //   target: `_blank`,
  //   icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
  //   badge: {
  //     color: 'info',
  //     text: '',
  //   },
  // },
  {
    component: CNavTitle,
    name: 'About',
  },
  // {
  //   component: CNavItem,
  //   name: 'Colors',
  //   to: '/theme/colors',
  //   icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'Profile',
  //   to: '/profile',
  //   icon: <CIcon icon={cilContact} customClassName="nav-icon" />,
  //   badge: {
  //     color: 'info',
  //     text: '',
  //     needsLoggedIn: true,
  //   },
  // },
  {
    component: CNavItem,
    name: 'About',
    to: '/about',
    icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Contact',
    to: '/contact',
    icon: <CIcon icon={cilEnvelopeOpen} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Terms & Conditions',
    to: '/terms',
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'Resume',
  //   to: '/resume',
  //   icon: (
  //     <>
  //       <CIcon icon={cilDescription} customClassName="nav-icon" />
  //     </>
  //   ),
  // },
  // {
  //   component: CNavTitle,
  //   name: 'My Software',
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Base',
  //   to: '/base',
  //   icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
  //   items: [
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Accordion',
  //     //   to: '/base/accordion',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Breadcrumb',
  //     //   to: '/base/breadcrumbs',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Cards',
  //     //   to: '/base/cards',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Carousel',
  //     //   to: '/base/carousels',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Collapse',
  //     //   to: '/base/collapses',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'List group',
  //     //   to: '/base/list-groups',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Navs & Tabs',
  //     //   to: '/base/navs',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Pagination',
  //     //   to: '/base/paginations',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Placeholders',
  //     //   to: '/base/placeholders',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Popovers',
  //     //   to: '/base/popovers',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Progress',
  //     //   to: '/base/progress',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Spinners',
  //     //   to: '/base/spinners',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Tables',
  //     //   to: '/base/tables',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Tooltips',
  //     //   to: '/base/tooltips',
  //     // },
  //   ],
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Buttons',
  //   to: '/buttons',
  //   icon: <CIcon icon={cilCursor} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Buttons',
  //       to: '/buttons/buttons',
  //     },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Buttons groups',
  //     //   to: '/buttons/button-groups',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Dropdowns',
  //     //   to: '/buttons/dropdowns',
  //     // },
  //   ],
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Forms',
  //   icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Form Control',
  //       to: '/forms/form-control',
  //     },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Select',
  //     //   to: '/forms/select',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Checks & Radios',
  //     //   to: '/forms/checks-radios',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Range',
  //     //   to: '/forms/range',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Input Group',
  //     //   to: '/forms/input-group',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Floating Labels',
  //     //   to: '/forms/floating-labels',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Layout',
  //     //   to: '/forms/layout',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Validation',
  //     //   to: '/forms/validation',
  //     // },
  //   ],
  // },
  // {
  //   component: CNavItem,
  //   name: 'Charts',
  //   to: '/charts',
  //   icon: <CIcon icon={cilChartPie} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Icons',
  //   icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'CoreUI Free',
  //       to: '/icons/coreui-icons',
  //       badge: {
  //         color: 'success',
  //         text: 'NEW',
  //       },
  //     },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'CoreUI Flags',
  //     //   to: '/icons/flags',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'CoreUI Brands',
  //     //   to: '/icons/brands',
  //     // },
  //   ],
  // },
  // {
  //   component: CNavGroup,
  //   name: 'My Software',
  //   icon: <CIcon icon={cibCodeigniter} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Murder Motel',
  //       to: 'https://breakintochat.com/wiki/Murder_Motel_(PC)',
  //       target: '_blank',
  //       rel: 'noopener norefferer',
  //       // badge: {
  //       //   color: 'info',
  //       //   text: 'July 1989',
  //       // },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Hippocampus ',
  //       to: 'http://lostwords.org/hippo.html',
  //       target: '_blank',
  //       rel: 'noopener norefferer',
  //       // badge: {
  //       //   color: 'info',
  //       //   text: 'July 1989',
  //       // },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Lostwords',
  //       // to: 'https://chrome.google.com/webstore/detail/lost-words/dokgckhhhphnjipidbihlkehmimoafde',
  //       to: 'http://lostwords.org',
  //       target: '_blank',
  //       rel: 'noopener norefferer',
  //       // badge: {
  //       //   color: 'info',
  //       //   text: 'July 1989',
  //       // },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Scrambled',
  //       // to: 'https://chrome.google.com/webstore/detail/lost-words/dokgckhhhphnjipidbihlkehmimoafde',
  //       to: 'http://www.lostwords.org/publicpuzzle/game.html',
  //       target: '_blank',
  //       rel: 'noopener norefferer',
  //       // badge: {
  //       //   color: 'info',
  //       //   text: 'July 1989',
  //       // },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Smart Sign Technology',
  //       to: 'https://smartsigntechnology.com/',
  //       target: '_blank',
  //       rel: 'noopener norefferer',
  //       // badge: {
  //       //   color: 'info',
  //       //   text: 'July 1989',
  //       // },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Surf Locks',
  //       to: 'https://www.facebook.com/surflocks/',
  //       target: '_blank',
  //       rel: 'noopener norefferer',
  //       // badge: {
  //       //   color: 'info',
  //       //   text: 'July 1989',
  //       // },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Virbi Tours',
  //       to: 'https://www.facebook.com/virbitours/',
  //       target: '_blank',
  //       rel: 'noopener norefferer',
  //       // badge: {
  //       //   color: 'info',
  //       //   text: 'July 1989',
  //       // },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'VET Locker',
  //       to: 'https://pasciak.com:3131/',
  //       target: '_blank',
  //       rel: 'noopener norefferer',
  //       // badge: {
  //       //   color: 'info',
  //       //   text: 'July 1989',
  //       // },
  //     },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Badges',
  //     //   to: '/notifications/badges',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Modal',
  //     //   to: '/notifications/modals',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Toasts',
  //     //   to: '/notifications/toasts',
  //     // },
  //   ],
  // },
  // {
  //   component: CNavTitle,
  //   name: 'Lost Words',
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Lost Words',
  //   // icon: <CIcon icon={cibCodeigniter} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Lostwords',
  //       to: 'http://lostwords.org',
  //       target: `_blank`,
  //       icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
  //       badge: {
  //         color: 'info',
  //         text: '',
  //       },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'NPM Package',
  //       to: 'https://www.npmjs.com/package/@pasciaks/lostwords-org-library',
  //       target: `_blank`,
  //       icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
  //       badge: {
  //         color: 'info',
  //         text: '',
  //       },
  //     },

  //     {
  //       component: CNavItem,
  //       name: 'NPM Package Demo',
  //       to: 'https://sheldon.pasciak.com/',
  //       target: `_blank`,
  //       icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
  //       badge: {
  //         color: 'info',
  //         text: '',
  //       },
  //     },
  //   ]
  // },
  // {
  //   component: CNavTitle,
  //   name: 'Web Presence',
  // },
  // {
  //   component: CNavGroup,
  //   name: 'My Software',
  //   icon: <CIcon icon={cibCodeigniter} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Murder Motel',
  //       to: 'https://breakintochat.com/wiki/Murder_Motel_(PC)',
  //       target: '_blank',
  //       rel: 'noopener norefferer',
  //       // badge: {
  //       //   color: 'info',
  //       //   text: 'July 1989',
  //       // },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Hippocampus ',
  //       to: 'http://lostwords.org/hippo.html',
  //       target: '_blank',
  //       rel: 'noopener norefferer',
  //       // badge: {
  //       //   color: 'info',
  //       //   text: 'July 1989',
  //       // },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Lostwords',
  //       // to: 'https://chrome.google.com/webstore/detail/lost-words/dokgckhhhphnjipidbihlkehmimoafde',
  //       to: 'http://lostwords.org',
  //       target: '_blank',
  //       rel: 'noopener norefferer',
  //       // badge: {
  //       //   color: 'info',
  //       //   text: 'July 1989',
  //       // },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Scrambled',
  //       // to: 'https://chrome.google.com/webstore/detail/lost-words/dokgckhhhphnjipidbihlkehmimoafde',
  //       to: 'http://www.lostwords.org/publicpuzzle/game.html',
  //       target: '_blank',
  //       rel: 'noopener norefferer',
  //       // badge: {
  //       //   color: 'info',
  //       //   text: 'July 1989',
  //       // },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Smart Sign Technology',
  //       to: 'https://smartsigntechnology.com/',
  //       target: '_blank',
  //       rel: 'noopener norefferer',
  //       // badge: {
  //       //   color: 'info',
  //       //   text: 'July 1989',
  //       // },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Surf Locks',
  //       to: 'https://www.facebook.com/surflocks/',
  //       target: '_blank',
  //       rel: 'noopener norefferer',
  //       // badge: {
  //       //   color: 'info',
  //       //   text: 'July 1989',
  //       // },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Virbi Tours',
  //       to: 'https://www.facebook.com/virbitours/',
  //       target: '_blank',
  //       rel: 'noopener norefferer',
  //       // badge: {
  //       //   color: 'info',
  //       //   text: 'July 1989',
  //       // },
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'VET Locker',
  //       to: 'https://pasciak.com:3131/',
  //       target: '_blank',
  //       rel: 'noopener norefferer',
  //       // badge: {
  //       //   color: 'info',
  //       //   text: 'July 1989',
  //       // },
  //     },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Badges',
  //     //   to: '/notifications/badges',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Modal',
  //     //   to: '/notifications/modals',
  //     // },
  //     // {
  //     //   component: CNavItem,
  //     //   name: 'Toasts',
  //     //   to: '/notifications/toasts',
  //     // },
  //   ],
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Facebook Sites',
  //   icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Sheldon Pasciak',
  //       to: 'https://www.facebook.com/sheldon.pasciak',
  //       // icon: <CIcon icon={icons.flagSet.cifUs} customClassName="nav-icon" />,
  //       target: '_blank',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Smart Sign',
  //       to: 'https://www.facebook.com/smartsigntechnology/',
  //       target: '_blank',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Surf Locks',
  //       to: 'https://www.facebook.com/surflocks/',
  //       target: '_blank',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Virbi-tours',
  //       to: 'https://www.facebook.com/virbitours/',
  //       target: '_blank',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'VET Locker',
  //       to: 'https://www.facebook.com/vendeverything/',
  //       target: '_blank',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Lostwords',
  //       to: 'https://www.facebook.com/lostwords.org/',
  //       target: '_blank',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Public Puzzle',
  //       to: 'https://www.facebook.com/publicpuzzle/',
  //       target: '_blank',
  //     },
  //   ],
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Pages',
  //   icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'Login',
  //       to: '/login',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Register',
  //       to: '/register',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Error 404',
  //       to: '/404',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'Error 500',
  //       to: '/500',
  //     },
  //   ],
  // },
  // {
  //   component: CNavItem,
  //   name: 'Docs',
  //   href: 'https://coreui.io/react/docs/templates/installation/',
  //   icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
  // },
  {
    component: CNavTitle,
    name: 'Survey',
  },
  {
    component: CNavItem,
    name: 'Egg Buddy',
    to: '/eggs',
    icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Geo Words',
    to: '/words',
    icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,
  },
]

export default _nav
