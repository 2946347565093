import { axiosCall } from './'

// Model API
export const modelAPI = {
  check: async () => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/`,
    })
  },
  getModels: async (accountId, modelName) => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/${accountId}/${modelName}`,
    })
  },
  getModel: async (accountId, modelName, id) => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/${accountId}/${modelName}/${id}`,
    })
  },
  postModel: async (accountId, modelName, data) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/${accountId}/${modelName}`,
      data: data,
    })
  },
  putModel: async (accountId, modelName, id, data) => {
    return await axiosCall({
      method: 'Put',
      url: `/api/v1/${accountId}/${modelName}/${id}`,
      data: data,
    })
  },
  patchModel: async (accountId, modelName, data) => {
    return await axiosCall({
      method: 'Patch',
      url: `/api/v1/${accountId}/${modelName}`,
      data: data,
    })
  },
  deleteModel: async (accountId, modelName, id) => {
    return await axiosCall({
      method: 'Delete',
      url: `/api/v1/${accountId}/${modelName}/${id}`,
    })
  },
}

// Test API
export const testAPI = {
  postTest: async (data) => {
    return await axiosCall({
      method: 'Post',
      url: `/invite`,
      data: data,
    })
  },
  getTest: async (data) => {
    return await axiosCall({
      method: 'Post',
      url: `/invites`,
      data: data,
    })
  },
  getTestSlots: async (data) => {
    return await axiosCall({
      method: 'Post',
      url: `/slots`,
      data: data,
    })
  },
}

// Events API
export const eventsAPI = { // @todo - //note - temporary non concurrent version of events demo, will overwrite all events, adapt later to true CRUD
  postEvents: async (data) => {
    return await axiosCall({
      method: 'Post',
      url: `/events`,
      data: data,
    })
  },
  getEvents: async (id = '') => { // @todo - //note - temporary non concurrent version of events demo
    return await axiosCall({
      method: 'Get',
      url: `/events/${id}`,
    })
  },
}

// Authorize API
export const authAPI = {
  postSignup: async (data) => {
    return await axiosCall({
      method: 'Post',
      url: `/signup`,
      data: data,
    })
  },
  postLogin: async (data) => {
    return await axiosCall({
      method: 'Post',
      url: `/login`,
      data: data,
    })
  },
  postLogout: async (data) => {
    return await axiosCall({
      method: 'Post',
      url: `/logout`,
      data: data,
    })
  },
  getStatus: async () => {
    return await axiosCall({
      method: 'Get',
      url: `/status`,
    })
  },
}

// Schedule API
export const scheduleAPI = {
  postSendAppt: async (data) => {
    return await axiosCall({
      method: 'Post',
      url: `/schedule`,
      data: data,
    })
  },
}

// Email API
export const eggsAPI = {
  postSendEggs: async (data) => {
    data = { ...data }
    return await axiosCall({
      method: 'Post',
      url: `/eggs`,
      data: data,
    })
  },
}

// webpush API

export const webpushAPI = {
  postSendWebpushNotification: async (data) => {
    data = { ...data }
    return await axiosCall({
      method: 'Post',
      url: `/send-notification`,
      data: data,
    })
  },
}

// Locations API
export const sendLocationsAPI = {
  postAddGps: async (data = { lat: 0, lng: 0 }) => {
    return await axiosCall({
      method: 'Post',
      url: `/add-gps`,
      data: data,
    })
  },
  // query.latitude = 40.6552576;
  // query.longitude = -111.9387648;
  getNearby: async (data) => {
    let { latitude, longitude, radius } = data;
    if (!latitude) { latitude = 40.6552576; }
    if (!longitude) { longitude = -111.9387648; }
    if (!radius) { radius = 10; }
    console.log(`/geo/location?latitude=${latitude}&longitude=${longitude}&radius=${radius}`);
    return await axiosCall({
      method: 'Post',
      url: `/geo/location?latitude=${latitude}&longitude=${longitude}&radius=${radius}`,
      data: data,
    })
  },
  postSendLocations: async (data) => {
    let theData = { array: data }
    return await axiosCall({
      method: 'Post',
      url: `/locations-api`,
      data: theData,
    })
  },
  postGetLocations: async (data) => {
    let theData = { array: data }
    return await axiosCall({
      method: 'Post',
      url: `/locations-api-get`,
      data: theData,
    })
  },
  postGetDBLocations: async (data = {}) => {
    return await axiosCall({
      method: 'Post',
      url: `/db-locations-get`,
      data
    })
  },
}

// Email API
export const emailAPI = {
  postSendEmail: async (to, from, data) => {
    data = { ...data, to, from }
    return await axiosCall({
      method: 'Post',
      url: `/email`,
      data: data,
    })
  },
}

// Address API
export const addressAPI = {
  getAddressListByEntityId: async (entity_id) => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/contact-address/list/${entity_id}`,
    })
  },
  createAddress: async (data) => {
    return await axiosCall({
      method: 'Post',
      url: `/api/v1/contact-address/`,
      data: data,
    })
  },
  updateAddress: async (addressId, data) => {
    return await axiosCall({
      method: 'Put',
      url: `/api/v1/contact-address/${addressId}`,
      data,
    })
  },
  deleteAddress: async (id) => {
    return await axiosCall({
      method: 'Delete',
      url: `/api/v1/contact-address/${id}`,
    })
  },
  getAddressById: async (id) => {
    return await axiosCall({
      method: 'Get',
      url: `/api/v1/contact-address/${id}`,
    })
  },
}
