import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { toast } from 'react-toastify'
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import {
  cilBell,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
  cilContact,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import avatar8 from './../../assets/images/avatars/me.jpg'

import { authAPI, modelAPI, emailAPI } from '../../services/api-services'

const checkExpires = (datetimestringzulu) => {
  // 2023-09-08T18:23:25.801Z
  let result = 0;
  let expireDate;
  let nowDate;
  try {
    if (!datetimestringzulu) { datetimestringzulu = new Date().toISOString() }
    expireDate = new Date(datetimestringzulu);
    nowDate = new Date();
    result = (((expireDate - nowDate) / 1000) / 60).toFixed(2);
    result; // minutes until expires
  } catch {
    result = -1;
  }
  // console.log("Cookies expires...");
  // console.log(datetimestringzulu);
  // console.log("Date/Time now...");
  // console.log(nowDate.toISOString());
  // console.log("Minutes remaining...");
  // console.log(result);
  try { localStorage.setItem('slp-token-mr', result); } catch { }
  try { localStorage.getItem('slp-token-mr'); } catch { }
  return result;
}

const AppHeaderDropdown = () => {
  const isLoggedIn = useSelector((state) => state.isLoggedIn)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const handleCallClicked = () => {
    if (prompt("What meeting id?") == '8675309') {
      window.location = "https://pasciak.com:3334/group.html";
    }
  }

  const handleEmailClicked = () => {
    emailAPI.postSendEmail('to@pasciak.com', 'from@pasciak.com', { message: "Test Message" })
  }

  const handleLogoutClicked = () => {
    // localStorage.clear();
    const keys = [];
    for (let i = 0; i < localStorage.length; i++) {
      keys.push(localStorage.key(i));
    }
    // console.log("keys:", keys);
    localStorage.removeItem("videoList");
    localStorage.removeItem("slp-token-mr");

    dispatch({ type: 'set', isLoggedIn: false })
    dispatch({ type: 'set', user: {} })
    dispatch({ type: 'set', data: {} })
    authAPI.postLogout()
  }

  // useEffect(() => {
  //   try {
  //     if ('serviceWorker' in navigator) {
  //       navigator.serviceWorker.register('/sw.js').then(reg => {
  //         navigator.serviceWorker.getRegistrations().then(regs => console.log(regs));
  //         console.log('Service Worker registered:', reg);
  //         Notification?.requestPermission().then(permission => {
  //           console.log(permission)
  //           try {
  //             subscribeUser().then((done) => {
  //               console.log("Subscribed")
  //             }).catch((err) => { console.log("Subscribe to push notifications error!") })
  //           } catch (err) {

  //           }
  //         });
  //       });
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, []);

  // useEffect(() => {
  //   try {
  //     if ('serviceWorker' in navigator) {
  //       navigator.serviceWorker.addEventListener('message', (event) => {
  //         console.log('Notification received in React:', event);
  //         // alert(`Notification: ${event.data.title} - ${event.data.body}`);
  //       });
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, []);

  function isFacebookInAppBrowser() {
    const ua = navigator.userAgent || navigator.vendor;
    return /FBAN|FBAV/i.test(ua);
  }

  if (isFacebookInAppBrowser()) {
    alert("For the best experience, please open this link in an external browser (Chrome or Safari). Please use the three dots in the Facebook messenger app to select 'Open With' to make this happen.");
  }

  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; i++) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  async function subscribeUser() {
    const registration = await navigator.serviceWorker.ready;
    const applicationServerKey = urlBase64ToUint8Array(process.env.REACT_APP_WEB_PUSH_PUBLIC);

    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey
    });

    await fetch('/subscribe', {
      method: 'POST',
      body: JSON.stringify(subscription),
      headers: { 'Content-Type': 'application/json' }
    });

    // console.log('User subscribed:', subscription);
  }


  // TODO: Refactor this for outsourcing use as a future deliberate call from Profile component for example...
  useEffect(() => {
    const checkStatus = async () => {
      // console.log(`Checking status..`)
      let checkStatusResult = await authAPI.getStatus()
      // console.log(checkStatusResult)
      if (checkExpires(checkStatusResult?.cookie?.expires || null) < -1000) {
        handleLogoutClicked()
      }
      dispatch({ type: 'set', ...checkStatusResult })
      dispatch({ type: 'set', data: { ...checkStatusResult } })
    }
    checkStatus()
    let timer = setInterval(checkStatus, 60 * 1000 * 1)
    return () => {
      clearInterval(timer)
    }
  }, [isLoggedIn]) // location  --  if location is added to this array, it will check between every route change

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar
          src={avatar8}
          size="md"
          style={isLoggedIn ? { border: '3px solid green' } : { border: '3px solid red' }}
        />
      </CDropdownToggle>
      {/* {!isLoggedIn && (<a href="/#/login">Login</a>)} */}
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>
        {/* <CDropdownItem href="#">
          <CIcon icon={cilBell} className="me-2" />
          Updates
          <CBadge color="info" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilEnvelopeOpen} className="me-2" />
          Messages
          <CBadge color="success" className="ms-2">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilTask} className="me-2" />
          Tasks
          <CBadge color="danger" className="ms-2">
            42
          </CBadge>
        </CDropdownItem> */}

        {/* <CDropdownHeader className="bg-light fw-semibold py-2">Settings</CDropdownHeader> */}
        {isLoggedIn && (
          <CDropdownItem href="/#/">
            <CIcon icon={cilSettings} className="me-2" />
            Home
          </CDropdownItem>
        )}
        {isLoggedIn && (
          <CDropdownItem href="/#/profile">
            <CIcon icon={cilContact} className="me-2" />
            Profile
          </CDropdownItem>
        )}

        {/* <CDropdownItem href="#">
          <CIcon icon={cilSettings} className="me-2" />
          Settings
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilCreditCard} className="me-2" />
          Payments
          <CBadge color="secondary" className="ms-2">
            42
          </CBadge>
        </CDropdownItem> */}
        {/* <CDropdownItem href="#">
          <CIcon icon={cilFile} className="me-2" />
          Projects
          <CBadge color="primary" className="ms-2">
            42
          </CBadge>
        </CDropdownItem> */}
        <CDropdownDivider />
        {!isLoggedIn && (
          <CDropdownItem href="/#/login">
            <CIcon icon={cilLockLocked} className="me-2" />
            Sign In
          </CDropdownItem>
        )}
        {!isLoggedIn && (
          <CDropdownItem href="/#/register">
            <CIcon icon={cilCreditCard} className="me-2" />
            Register
          </CDropdownItem>
        )}
        {isLoggedIn && (
          <CDropdownItem onClick={handleLogoutClicked} href="#">
            <CIcon icon={cilLockLocked} className="me-2" />
            Sign Out
          </CDropdownItem>
        )}
        <CDropdownDivider />
        {isLoggedIn && (
          <CDropdownItem disabled onClick={handleEmailClicked} href="#">
            <CIcon icon={cilFile} className="me-2" />
            Send Email
          </CDropdownItem>
        )}
        {isLoggedIn && (
          <CDropdownItem disabled onClick={handleCallClicked} href="#">
            <CIcon icon={cilFile} className="me-2" />
            Group Video Call
          </CDropdownItem>
        )}
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
